import React from "react";
import Head from "../components/head.js";

import { Link, graphql, useStaticQuery } from "gatsby";

// import * as blogStyles from "./blog.module.css";

import Layout from "../components/layout";

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title="Blog" />

      <ul>
        {data.allMarkdownRemark.edges.map((edge) => {
          return (
            <Link to={`/blog/${edge.node.fields.slug}`}>
              <li>
                <h3>{edge.node.frontmatter.title}</h3>
                <p>{edge.node.frontmatter.date}</p>
              </li>
            </Link>
          );
        })}
      </ul>
    </Layout>
  );
};

export default BlogPage;
